* {
  margin: 0;
  padding: 0;
  outline: none;
  touch-action: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

html, body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
}

body {
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #3D3D3D;
  overscroll-behavior: contain;
  overscroll-behavior-y: contain;
}

.visually-hidden {
  display: none;
}